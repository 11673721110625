<template>
  <b-overlay :show="loading">
    <b-row class="justify-content-center">
      <form-tak-tertagih @submit="doBadDebt"></form-tak-tertagih>
      <b-col md="10" lg="8" sm="12">
        <div class="alert alert-danger p-1" v-if="penjualan && penjualan.tak_tertagih == 1">
          <i>Penjualan ini telah ditandai menjadi piutang tak tertagih oleh <strong>Finance.</strong></i>
        </div>
        <!-- Detail Transaksi -->
        <b-card>
          <b-row class="mb-4">
            <b-col sm="12" md="6">
              <h4 class="">
                <strong> Detail Transaksi </strong>
              </h4>
            </b-col>
            <b-col sm="12" md="6" v-if="isFinance && penjualan && penjualan.status == 0">
              <a href="#" class="d-block text-right text-danger" v-if="penjualan.tak_tertagih == 0" @click.prevent="confirmBadDebt">Tandai sebagai piutang tak tertagih?</a>
              <a href="#" class="d-block text-right text-primary" v-else @click.prevent="cancelBadDebt">Batalkan sebagai piutang tak tertagih</a>
            </b-col>
          </b-row>
          <b-row>
            <!-- Informasi Data -->
            <b-col md="9" sm="12">
              <b-row class="mb-2">
                <b-col cols="6">
                  <b-form-group label="No. Nota">
                    <strong>{{ penjualan ? penjualan.no_kwitansi : "" }}</strong>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Konsumen">
                    <router-link v-if="penjualan && penjualan.konsumen" :to="`/ajuankonsumen/${penjualan.konsumen ? penjualan.konsumen.id : ''}`">
                      <strong>{{ penjualan.konsumen ? penjualan.konsumen.nama_toko : penjualan.id_konsumen }}</strong>
                    </router-link>
                    <strong v-else class="font-italic">Data Konsumen Terhapus</strong>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Sales">
                    <strong>{{ penjualan && penjualan.sales ? penjualan.sales.nama_lengkap : "" }}</strong>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Tanggal Transaksi">
                    <strong>{{ penjualan ? penjualan.tanggal : "-" }}</strong>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Status Transaksi">
                    <b-badge variant="success" v-if="penjualan && penjualan.status == 1"> Lunas </b-badge>
                    <b-badge variant="danger" v-else> Belum Lunas </b-badge>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Status Pembayaran">
                    <b-badge variant="danger" v-if="penjualan && penjualan.bayar == 0"> Belum Bayar </b-badge>
                    <b-badge variant="success" v-else> Sudah Bayar </b-badge>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6">
                  <b-form-group label="Rute Penjualan">
                    <i class="text-danger" v-if="!rute">Rute belum ada</i>
                    <strong v-else>{{ rute.nama_rute }}</strong>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- Button -->
            <b-col md="3" sm="12">
              <b-button @click.prevent="viewBayar" class="mb-1" block variant="warning" v-if="isSales && penjualan && penjualan.status == 0 && penjualan.tak_tertagih == 0"> Bayar </b-button>
              <b-button variant="outline-danger" block @click.prevent="printNotaPenjualan">
                <feather-icon icon="PrinterIcon"></feather-icon>
                Nota Penjualan
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <!-- Informasi Pembayaran -->
        <b-card>
          <h4 class="mb-2">
            <strong>Informasi Pembayaran <span v-if="!thereIsDebt">(Lunas)</span></strong>
          </h4>
          <ul class="p-1 list-unstyled w-100">
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">Total Harga Barang</div>
              <div class="detail-amt">
                <strong>{{ penjualan ? formatRupiah(penjualan.total_bayar) : 0 }}</strong>
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">Pengurangan Saldo</div>
              <div class="detail-amt">
                <strong class="text-danger">-{{ penjualan ? formatRupiah(penjualan.saldo) : "" }}</strong>
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">Total Pembayaran</div>
              <div class="detail-amt">
                <strong :class="thereIsDebt ? '' : 'text-success'">{{ penjualan ? formatRupiah(penjualan.total_pembayaran) : 0 }}</strong>
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1" v-if="thereIsDebt">
              <div class="details-title">Hutang</div>
              <div class="detail-amt">
                <strong class="text-danger">{{ formatRupiah(debt) }}</strong>
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1" v-if="thereIsChange">
              <div class="details-title">Kembalian</div>
              <div class="detail-amt">
                <strong class="text-danger">{{ formatRupiah(change) }}</strong>
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">Total Diskon</div>
              <div class="detail-amt">
                <strong class="text-danger">-{{ penjualan ? formatRupiah(penjualan.diskon) : "" }}</strong>
              </div>
            </li>
          </ul>
        </b-card>

        <!-- Rincian Barang -->
        <b-card-actions collapsed :title="`Rincian Barang (${penjualan && penjualan.rincian ? penjualan.rincian.length : 0})`" action-collapse>
          <b-table responsive bordered striped hover :fields="barangFields" :items="penjualan ? penjualan.rincian : []" :per-page="10" :current-page="currentPage">
            <template #cell(barang)="{ item }">
              <span v-if="item.barang">
                {{ item.barang.nama }}
              </span>
              <i v-else class="text-danger"> Barang dihapus atau hilang </i>
            </template>
            <template #cell(qty)="{ item }"> {{ item.qty }} {{ item.barang && item.barang.satuan ? item.barang.satuan.satuan : "" }} </template>
            <template #cell(harga_jual)="{ item }"> Rp {{ formatRupiah(item.harga_jual) }} </template>
            <template #cell(diskon)="{ item }"> Rp {{ formatRupiah(item.diskon) }} </template>
            <template #cell(harga_total)="{ item }"> Rp {{ formatRupiah(parseInt(item.qty) * parseInt(item.harga_jual) - item.diskon) }} </template>
          </b-table>
          <b-row>
            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="10" align="center" size="sm" class="my-0" />
            </b-col>
          </b-row>
        </b-card-actions>

        <!-- Detail Pembayaran -->
        <b-card-actions
          :title="`History Pembayaran (${penjualan && penjualan.pembayaran && penjualan.pembayaran.length > 0 ? penjualan.pembayaran.length : ''})`"
          v-if="penjualan && penjualan.pembayaran && penjualan.pembayaran.length > 0"
          collapsed
          action-collapse
        >
          <ul class="border p-1 list-unstyled w-100 mb-1" v-for="(payment, i) in penjualan.pembayaran" :key="i">
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">Metode Pembayaran</div>
              <div class="detail-amt">
                <strong>{{ getMetode(payment.metode) }}</strong>
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">Nominal</div>
              <div class="detail-amt">
                <strong>{{ formatRupiah(getNominal(payment)) }}</strong>
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">Jumlah Bayar</div>
              <div class="detail-amt">
                <strong>{{ formatRupiah(penjualan.total_bayar) }}</strong>
              </div>
            </li>
            <li v-if="isTransfer(payment.metode)" class="d-flex justify-content-between mb-1">
              <div class="details-title">Bank Tujuan</div>
              <div class="detail-amt">
                <strong>{{ payment.bank.toUpperCase() }}</strong>
              </div>
            </li>
            <li v-if="payment.tgl_pembayaran" class="d-flex justify-content-between mb-1">
              <div class="details-title">
                {{ isGiro(payment.metode) ? "Tanggal Transaksi" : "Tanggal Pembayaran" }}
              </div>
              <div class="detail-amt">
                <strong>{{ getDate(payment.tgl_pembayaran) }}</strong>
              </div>
            </li>
            <li v-if="isGiro(payment.metode)" class="d-flex justify-content-between mb-1">
              <div class="details-title">No. Giro</div>
              <div class="detail-amt">
                <strong>{{ payment.no_giro }}</strong>
              </div>
            </li>
            <li v-if="isGiro(payment.metode)" class="d-flex justify-content-between mb-1">
              <div class="details-title">Tanggal Giro</div>
              <div class="detail-amt">
                <strong>{{ payment.tgl_giro }}</strong>
              </div>
            </li>
            <li v-if="isGiro(payment.metode)" class="d-flex justify-content-between mb-1">
              <div class="details-title">Tanggal Bayar</div>
              <div class="detail-amt">
                <strong>{{ payment.tgl_bayar }}</strong>
              </div>
            </li>
            <li v-if="isGiro(payment.metode)" class="d-flex justify-content-between mb-1">
              <div class="details-title">No. Rekening</div>
              <div class="detail-amt">
                <strong>{{ payment.norek }}</strong>
              </div>
            </li>
            <li v-if="isGiro(payment.metode)" class="d-flex justify-content-between mb-1">
              <div class="details-title">Pemilik</div>
              <div class="detail-amt">
                <strong>{{ payment.pemilik }}</strong>
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">Ket.</div>
              <div class="detail-amt">
                <strong>{{ payment.keterangan }}</strong>
              </div>
            </li>
          </ul>
        </b-card-actions>
        <!-- Batalkan Transaksi -->
        <b-card v-if="isSales">
          <h4 class="mb-2"><strong>Lainnya</strong></h4>
          <ul v-if="penjualan && penjualan.bayar == 1" class="p-1 list-unstyled w-100 mb-1">
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">
                <h5>
                  <strong>Batalkan Transaksi?</strong>
                </h5>
              </div>
              <div class="detail-amt">
                <b-button size="sm" variant="outline-danger" @click.prevent="cancelTransaction">Batalkan</b-button>
              </div>
            </li>
          </ul>
          <ul v-if="penjualan && penjualan.bayar == 0" class="p-1 list-unstyled w-100 mb-1">
            <li class="d-flex justify-content-between mb-1">
              <div class="details-title">
                <h5>
                  <strong>Hapus Transaksi?</strong>
                </h5>
              </div>
              <div class="detail-amt">
                <b-button size="sm" variant="outline-danger" @click.prevent="removeTransaction">Hapus</b-button>
              </div>
            </li>
          </ul>
        </b-card>

        <!-- Form cancel -->
        <b-modal id="cancel-form" no-close-on-backdrop no-close-on-esc>
          <b-form-group label="Tulis alasan anda membatalkan transaksi">
            <b-form-input v-model="cancelForm.keterangan"></b-form-input>
          </b-form-group>

          <template #modal-footer>
            <b-button variant="primary" @click.prevent="doCancel">Simpan</b-button>
          </template>
        </b-modal>
        <!-- / -->
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import { BOverlay, BCard, BFormInput, BRow, BCol, BPagination, BTable, BAlert, BButton, BFormGroup, BBadge } from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import FormTakTertagih from "./components/FormTakTertagih.vue";
export default {
  components: {
    BFormInput,
    BOverlay,
    BPagination,
    BCardActions,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BBadge,
    BAlert,
    BTable,
    vSelect,
    FormTakTertagih,
  },
  data: () => ({
    loading: false,
    cancelForm: {
      keterangan: null,
    },
    rute: null,
    currentPage: 1,
    totalRows: 0,
    barangFields: [
      { key: "barang", label: "Nama Barang" },
      { key: "qty", label: "Jumlah Barang" },
      { key: "harga_jual", label: "Harga Satuan" },
      { key: "diskon", label: "Diskon" },
      { key: "harga_total", label: "Harga Total" },
    ],
    penjualan: null,
    kasOptions: [],
    payments: [
      {
        metode: 1,
        nominal: 20000,
      },
    ],
    selectedKas: null,
  }),
  methods: {
    async doBadDebt(payload) {
      try {
        this.$bvModal.hide("form-tak-tertagih");
        this.loading = true;
        await this.$store.dispatch("penjualan/doBadDebt", payload);
        this.loading = false;
        // update page
        const penjualan = await this.$store.dispatch("penjualan/getDataById", this.$route.params.id);
        if (penjualan) {
          this.penjualan = penjualan;
          this.totalRows = this.penjualan.rincian ? this.penjualan.rincian.length : 0;
        }
        this.displaySuccess({
          message: "Penjualan telah ditandai sebagai piutang tak tertagih!",
        });
      } catch (e) {
        this.$bvModal.show("form-tak-tertagih");
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    cancelBadDebt() {
      this.$swal({
        title: "Batalkan penandaan piutang tak tertagih?",
        text: "Dengan ini, maka sales dapat menyelesaikan penjualan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-outline-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (res) => {
        if (res.value) {
          const payload = {
            id: this.$route.params.id,
          };

          try {
            this.loading = true;
            await this.$store.dispatch("penjualan/cancelBadDebt", payload);
            this.loading = false;

            // update page
            const penjualan = await this.$store.dispatch("penjualan/getDataById", this.$route.params.id);
            if (penjualan) {
              this.penjualan = penjualan;
              this.totalRows = this.penjualan.rincian ? this.penjualan.rincian.length : 0;
            }

            this.displaySuccess({
              message: "Piutang tak tertagih di penjualan ini berhasil dibatalkan!",
            });
          } catch (e) {
            this.loading = false;
            this.displayError(e);
            return false;
          }
        }
      });
    },
    confirmBadDebt() {
      this.$swal({
        title: "Tandai penjualan ini sebagai piutang tak tertagih?",
        text: "Pastikan pembayaran penjualan ini sudah disetor semua",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-outline-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (res) => {
        if (res.value) {
          // validate ada pembayaran yang belum disetor

          // set total debt pembayaran
          const unpaidPayments = this.penjualan.pembayaran.filter((py) => py.disetor == 0);
          if (unpaidPayments.length > 0) {
            this.displayError({
              message: "Masih ada pembayaran yg belum disetor, hubungi sales yang bersangkutan!",
            });
            return false;
          } else {
            const totalPayment = parseInt(this.penjualan.sisa_hutang);
            this.$store.commit("penjualan/SET_TOTAL_BAD_DEBT", totalPayment);
            this.$bvModal.show("form-tak-tertagih");
          }
        }
      });
    },
    isTransfer(metodeId) {
      return metodeId == 2;
    },
    isGiro(metodeId) {
      return metodeId == 3;
    },
    async getRute() {
      if (!this.penjualan || !this.penjualan.rute_id) {
        return false;
      }

      const rute = await this.$store.dispatch("rute/getDataById", this.penjualan.rute_id);
      this.rute = rute;
    },
    async printNotaPenjualan() {
      this.loading = true;
      const response = await this.$store.dispatch("penjualan/printNota", this.$route.params.id);
      this.loading = false;
      window.open(URL.createObjectURL(response));
    },
    getDate(dateAndTime) {
      if (dateAndTime.split(" ")) {
        return dateAndTime.split(" ")[0];
      }
      return dateAndTime;
    },
    async getAkun() {
      const akuns = await this.$store.dispatch("akun/getData");
      const akunOptions = akuns.map((akun) => ({ text: `${akun.nama} -> Rp ${this.formatRupiah(akun.saldo)}`, value: akun.id, saldo: akun.saldo, kategori: akun.kategori }));
      this.$store.commit("penjualan/SET_DATA_AKUN", akunOptions);
    },
    async getKas() {
      const listKas = await this.$store.dispatch("kas/getData");
      const kasOptions = listKas.map((kas) => ({ text: `${kas.nama} -> Rp ${this.formatRupiah(kas.saldo)}`, saldo: kas.saldo, value: kas.id }));
      this.$store.commit("penjualan/SET_DATA_KAS", kasOptions);
    },
    viewBayar() {
      const { total_bayar: totalBayar, total_pembayaran, diskon, saldo } = this.penjualan;
      const jumlahHutang = Math.abs(this.unFormatRupiah(this.debt));
      // define total yg harus dibayar
      this.$store.commit("penjualan/SET_CURRENT_TOTAL_BAYAR", totalBayar);
      this.$store.commit("penjualan/SET_CURRENT_TOTAL_HUTANG", jumlahHutang);
      this.$router.push(`/penjualan/bayar/${this.penjualan.id}`);
    },
    removeTransaction() {
      this.$swal({
        title: "Anda yakin?",
        text: "Data transaksi akan hilang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-outline-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.doRemove();
        }
      });
    },
    async doRemove() {
      const id = this.$route.params.id;
      try {
        // cancel transaction first
        const cancelPayload = {
          id_penjualan: this.penjualan.id,
          tanggal: this.penjualan.tanggal,
          no_kwitansi: this.penjualan.no_kwitansi,
          keterangan: this.penjualan.keterangan ? this.penjualan.keterangan : "-",
        };
        await this.$store.dispatch("penjualan/cancel", [cancelPayload]);
        await this.$store.dispatch("penjualan/remove", {
          id_penjualan: id,
        });
        this.displaySuccess({
          message: "Data transaksi penjualan telah dihapus",
        });
        this.$router.push(`/penjualan`);
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    cancelTransaction() {
      this.$swal({
        title: "Anda yakin?",
        text: "Setelah dibatalkan, semua history pembayaran akan hilang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-outline-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$bvModal.show("cancel-form");
          // this.doCancel()
        }
      });
    },
    async doCancel() {
      const penjualan = this.$store.getters["penjualan/search"](this.$route.params.id);
      if (!penjualan) {
        this.$router.push(`/penjualan`);
      }

      const payload = {
        id_penjualan: penjualan.id,
        tanggal: penjualan.tanggal,
        no_kwitansi: penjualan.no_kwitansi,
        keterangan: this.cancelForm.keterangan,
      };

      try {
        this.$bvModal.hide("cancel-form");
        this.loading = true;
        await this.$store.dispatch("penjualan/cancel", [payload]);
        await this.getAllPenjualan();
        this.displaySuccess({
          message: "Data transaksi penjualan telah dibatalkan",
        });
        this.loading = false;

        setTimeout(() => window.location.reload(), 1000);
      } catch (e) {
        this.loading = false;
        this.$bvModal.show("cancel-form");
        this.displayError(e);
        return false;
      }
    },
    getNominal(item) {
      if (item.metode == 1) {
        return item.tunai;
      } else if (item.metode == 2) {
        return item.transfer;
      }

      return item.giro;
    },
    getPembayaran(payment) {
      // if(payment.)
    },
    getMetode(code) {
      if (code == 1) {
        return "Tunai";
      } else if (code == 2) {
        return "Transfer";
      } else if (code == 3) {
        return "Giro";
      }

      return "Nota Putih";
    },
    async getPenjualan(newdata = false) {
      await this.getAllPenjualan();
      let penjualan = this.$store.getters["penjualan/search"](this.$route.params.id);
      if (!penjualan) this.$router.push("/penjualan");
      this.penjualan = await penjualan;
      this.$store.commit("penjualan/SET_ONE_DATE", penjualan);
      this.totalRows = this.penjualan.rincian ? this.penjualan.rincian.length : 0;
    },
    async getAllPenjualan() {
      const params = this.isSales ? { order: "desc", id_sales: this.user.karyawan.id } : { order: "desc" };
      await this.$store.dispatch("penjualan/getData", params); // belum difilter per sales
    },
  },
  computed: {
    thereIsDebt() {
      const jumlahDiskon = this.penjualan ? parseInt(this.penjualan.diskon) + parseInt(this.penjualan.saldo) : 0;
      if (this.penjualan) {
        return this.penjualan.total_pembayaran < this.penjualan.total_bayar;
      }

      return 0;
    },
    debt() {
      const jumlahDiskon = this.penjualan ? parseInt(this.penjualan.diskon) : 0;

      if (this.penjualan) {
        let totalBayar = this.penjualan.total_pembayaran;
        if (this.penjualan.saldo) {
          totalBayar += parseInt(this.penjualan.saldo);
        }
        return totalBayar - this.penjualan.total_bayar;
      }
      return 0;
    },
    thereIsChange() {
      const tagihan = this.penjualan ? this.penjualan.total_bayar : 0; // sudah dipotong diskon
      let totalBayar = 0;
      if (this.penjualan && this.penjualan.pembayaran && this.penjualan.pembayaran.length > 0) {
        totalBayar += this.penjualan.pembayaran.reduce((total, item) => (total += item.nominal), 0);
      }
      return totalBayar > tagihan;
    },
    change() {
      const tagihan = this.penjualan ? this.penjualan.total_bayar : 0; // sudah dipotong diskon
      console.log("tagihan", this.penjualan);
      if (this.penjualan) {
        let totalBayar = this.penjualan.pembayaran.reduce((total, item) => (total += item.nominal), 0);
        return totalBayar - tagihan;
      }

      return 0;
    },
  },
  async created() {
    this.getKas();
    await this.getPenjualan();
    this.getRute();

    if (this.isFinance) {
      await this.getAkun();
      await this.getKas();
    }
  },
};
</script>
